import './App.css';
import { GoogleSheetsInput } from './components/google-sheets-input';
import { useState, useEffect, Fragment } from 'react';

import { ThemeProvider } from 'styled-components';
import { LightTheme } from './Theme';
import { useGoogleSheets, getID, FETCH_STATE } from './hooks/useGoogleSheet';

import { useParams, useHistory } from "react-router-dom";
import { Bubbles } from './components/bubbles';

// import RefreshIcon from "./icons/ArrowsClockwise.svg";
import { ReactComponent as RefreshIcon } from './icons/refresh.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as Logo } from './icons/logo.svg';
import { ModalContent, Modal, ModalStatusText } from './components/modal';

function App() {

  const appName = document.title;

  const [categories, setCategories] = useState([]);

  const [data, setData] = useState();
  const [refresh, setRefresh] = useState();
  const [columns, setColumns] = useState();
  const [now, setNow] = useState(new Date());
  let history = useHistory();
  const { dataID, selectedBubble } = useParams();
  
  let interval;
 
  const { status, result } = useGoogleSheets(refresh);

  const onNewDataURL = dataURL => {               
      //setDataURL(dataURL);
      let id = getID(dataURL);
      if(id) history.push("/" + id ? id : '');
  }

  const setSelectedBubble = (bubbleID) => {
    if(bubbleID) history.push(`/${dataID}/${bubbleID}`);
    else history.push(`/${dataID}`);
  }

  
 
  const onResults = _ => {

    if(result && !result.error && result.result)
    {
      document.title = `${result.title} - ${appName}`;
      setCategories(result.result.categories);
      setColumns(result.result.columns);
      setData(result.result.values);
      // mapData(result.result.values);
    }
    else
    {
      document.title = `${appName}`;
      setCategories([]);
      setColumns();
      setData();
      // mapData();
    }
  }

  

  const clearData = () => {
    setCategories([]);
    setData();
    history.push("/");
  }

  const getRefreshTime = () => {
    if(status === FETCH_STATE.fetching)
    {
      return 'Loading...';
    }

    if(result && result.dateTime)
    {
      let diff = now.getTime() - result.dateTime.getTime();
      let mins = Math.max(0, Math.floor(diff / 60000));
      return `Last refreshed ${mins}m ago.`
    }

    return '';
  }

  const reload = () => {
    setRefresh(Math.random());
  };

  const getModalMessage = () => {
      switch(status)
      {
        case FETCH_STATE.fetching:
          return (<ModalStatusText>Loading</ModalStatusText>);
        case FETCH_STATE.error:
          if(dataID)
          {
            return (
              <Fragment>
                <h1>Error</h1>
                <p>Sorry, there was an error loading that spreadsheet.</p>
                <p>To continue please paste your google spreadsheet url below.</p>
                <GoogleSheetsInput status={dataID ? status : FETCH_STATE.idle} onURL={onNewDataURL}/> 
              </Fragment>
            )
          }
        default:
          return (
              <Fragment>
                <h1>Welcome</h1>
                <p>To get started, please paste your google spreadsheet url below.</p>
                <GoogleSheetsInput status={dataID ? status : FETCH_STATE.idle} onURL={onNewDataURL}/> 
              </Fragment>
          )
          
      }
  }

  // const onOver = (id, x, y) => {
  //   if(id) setHoverBubble({id, x, y});
  //   else setHoverBubble()
  // }

  useEffect(onResults, [result]);
  useEffect(() => {
    interval = setInterval(() => setNow(new Date()), 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ThemeProvider theme={LightTheme} className="App">
      <header className="App-header">
        <div className="header-group">
          <Logo/>
          <h1>{result && result.title ? result.title : ''}</h1>
        </div> 
        
        {data && (
          <div className="header-group menu">
            <p>{getRefreshTime()}</p>
            <button onClick={reload} className="solid"><span className="icon" ><RefreshIcon /></span><span>Refresh</span></button>
            <button onClick={clearData} className="solid"><span className="icon" ><PlusIcon /></span><span>New</span></button>
          </div>
        )}
      </header>
      
    
      <main>
        <Bubbles data={data} columns={columns} categories={categories} onSelect={setSelectedBubble} selected={selectedBubble}/>        
     
        {!data && (
          
            <Modal position={{x: '50vw', y: '50vh'}}>
              <ModalContent> 
                { getModalMessage() }
              </ModalContent>
            </Modal>


        )}

        

      </main>

    </ThemeProvider >
  );
}

export default App;
