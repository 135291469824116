import { useState, useEffect } from 'react';
import { BubbleChart } from "./bubble-chart";
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { ModalContent, Modal, MODAL_SIZE, MODAL_POSITION } from '../components/modal';

function Bubbles({data, categories, onSelect, selected, columns}){

    const [bubbles, setBubbles] = useState([]);
    const [selectableBubbles, setSelectableBubbles] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [mappedData, setMappedData] = useState({});
    const [hoverBubble, setHoverBubble] = useState();


    const resetSelected = () => {
        setSelectedCategory();
    }

    const onHover = (bubble) => {

        if(bubble && bubble.id) setHoverBubble({id: bubble.id, x: bubble.x, y: bubble.y});
        else setHoverBubble()
    }

    const colors = {
        active: '#FC3718',
        inactive: '#D4D8E1'
      }
    
      const mapData = (data) => {
        let newMap = {};
        if(data)
        {
          data.forEach(d => {
            newMap[d.label] = d;
          });
        }
        setMappedData(newMap);
      }

    const onClick = (bubble) => {
        onSelect(bubble.id);
    }

    const nextBubble = () => {
      if(selected)
      {
          let i = selectableBubbles.indexOf(selected) + 1;
          if(!i || i >= selectableBubbles.length) i = 0;
          
          onSelect(selectableBubbles[i]);
      }
    }

    const updateBubbles = () => {
        if(!data) {
          setBubbles([])
          mapData();
        }
        else {
          let selectable = [];
          const newBubbles = data.map(d => {
            const active = !selectedCategory || (selectedCategory && selectedCategory === d.category);
            if(active) selectable.push(d.label);

            const getSize = () => {
              if(selected)
              {
                  if(d.label === selected) return 1;
                  if(active) return 0.2;
              }
              if(active) return 0.8 + (Math.random() * 0.2);
              return 0.1;
            }

            const getPosition = () => {
              if(selected)
              {
                if(d.label === selected) return {x: .25, y: .5};
                return {x: .66, y: .5}
              }

              // return {x: (1 / 6) * Math.ceil(Math.random() * 5), y: (1 / 6) * Math.ceil(Math.random() * 5)}
              return {x: .5, y: .5}
            }

            return {
              id: d.label,
              label: d.label,
              size: getSize(),
              color: active ? colors.active : colors.inactive,
              interactive: active,
              position: getPosition()
            }
          });
          setBubbles(newBubbles);
          mapData(data);
          setSelectableBubbles(selectable);
        }
      }

    useEffect(updateBubbles, [data, selectedCategory, selected])
    useEffect(resetSelected, [categories])

    const getModalPosition = () => {
      if(selected) return {x: '66vw', y: '50vh'};
      if(hoverBubble) return {x: hoverBubble.x, y: hoverBubble.y};
      return null;
    }

    return(
        <section>
            <div className="chart-container">
              <BubbleChart data={bubbles} onOver={onHover} onSelect={onClick} selected={selected}/>
            </div>
            <ul className="categories">
              {categories.map(category => (<li key={category} className={selectedCategory === category ? 'active' : ''}><button onClick={() => setSelectedCategory(category)}>{category}</button></li>))}
              {selectedCategory  && <li ><button onClick={() => setSelectedCategory()}><span className="icon"><CloseIcon/></span></button></li>}
            </ul>

            <Modal onNext={(selected && selectableBubbles.length > 1) ? () => nextBubble() : null} offset={hoverBubble && mappedData[hoverBubble.id] ? MODAL_POSITION.right : MODAL_POSITION.center} clickThrough={!selected} blur={false} onClose={() => { onSelect(); onHover() }} position={getModalPosition()}>
            { hoverBubble && mappedData[hoverBubble.id] && !selected && (
              <ModalContent size={MODAL_SIZE.small}>
                <h2>{mappedData[hoverBubble.id].label}</h2>
                {mappedData[hoverBubble.id].idea && (<p>{mappedData[hoverBubble.id].idea}</p>) }
              </ModalContent> 
            )}
            {
              selected && mappedData[selected] && (
                <ModalContent size={MODAL_SIZE.large}>
                  <h1>{mappedData[selected].label}</h1>
                  <p>{mappedData[selected].idea}</p>
                  <div className="category">{mappedData[selected].category}</div>
                  {columns.filter(column => mappedData[selected][column] && mappedData[selected][column].length).map(column => (
                    <div key={column}>
                      <h2>{column}</h2>  
                      { mappedData[selected][column] && mappedData[selected][column].split('\n').length > 1 ? 
                        (
                          <ul>
                            {mappedData[selected][column].split('\n').map((d, i) => (
                              <li key={i}>{d}</li>
                            ))}
                          </ul> 
                        ) : (
                          <p> {mappedData[selected][column]}</p>
                        )}
                      
                    </div> 
                  ))}
                  
                </ModalContent>
              )
            }
        </Modal>
        </section>
    )
}

export { Bubbles }; 