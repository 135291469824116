import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const FETCH_STATE = {
    idle: 'idle',
    fetching: 'fetching',
    fetched: 'fetched',
    error: 'error'
}

const getID = url => {

    if(!url) return null;

    // const urlRegEx = /(?<=\bspreadsheets\/d\/)([a-zA-Z0-9-_]+)/;
    const urlRegEx = /(spreadsheets\/d\/)([a-zA-Z0-9-_]+)/;
    const idStringRegEx = /^[a-zA-Z0-9-_]+$/;
    let id = urlRegEx.exec(url);

    if(id && id.length){
        console.log(id);
        return id[2];
    }
    
    let idCheck = url.split('?')[0]
    
    if(idStringRegEx.test(idCheck)){
        return idCheck;
    }

    console.log('Not a valid ID or URL', url)
    return null;
}

const createPropertiesURL = id => `https://sheets.googleapis.com/v4/spreadsheets/${id}?key=${process.env.REACT_APP_GOOGLE_API_KEY}&fields=properties.title`;
const createDataURL = id => `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Sheet1!A1:H1000?key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

const useGoogleSheets = (refresh) => {
    const [status, setStatus] = useState(FETCH_STATE.idle);
    const [result, setResult] = useState();
    let { dataID } = useParams();

    const parseData = rawData => {
        let keys = rawData.shift().map(d => d.toLowerCase());
        let categories = [];
        let doNotInclude = ['label', 'category', 'idea']
        let columns = keys.filter(d => doNotInclude.indexOf(d) < 0);
        let values = rawData.map(d => {
            let mapped = {};
            keys.forEach((key, i) => {
                mapped[key] = d[i] ? d[i] : null;
                if(key.toLowerCase() === 'category' && d[i] && d[i].length){
                    if(categories.indexOf(d[i]) < 0) categories.push(d[i])
                }
            });
            return mapped;
        })
    
        return { categories, values, columns }
    }

    useEffect(() => {
        
        console.log('NEW ID', dataID);

        if(!dataID){
            console.log('no id, clearing data')
            setResult();
            setStatus(FETCH_STATE.idle);//'Please enter a valid Google Sheets URL or ID.');
        }
        else
        { 
            let propRequest = createPropertiesURL(dataID);
            let dataRequest = createDataURL(dataID);

            const fetchData = async () => {
                setStatus(FETCH_STATE.fetching);

                let dataResponse;
                let dataJSON;
                let propsResponse;
                let propsJSON;

                let err = null;

                try
                {
                    propsResponse = await fetch(propRequest);
                    propsJSON = await propsResponse.json();
                }
                catch(error)
                {
                    err = error;
                }
                
                if(!err)
                {
                    try
                    {
                        dataResponse = await fetch(dataRequest);
                        dataJSON = await dataResponse.json();
                        err = dataJSON.error;
                    }
                    catch(error)
                    {
                        err = error;
                    }
                }
                else
                {
                    err = propsJSON.error;
                }
                
                const res = {
                    error: err, 
                    result: dataJSON.values ? parseData(dataJSON.values) : null, 
                    title: propsJSON.properties ? propsJSON.properties.title : null,
                    dateTime: new Date()
                };

                setStatus(err ? FETCH_STATE.error : FETCH_STATE.fetched);
                setResult(res);
            };

            if(propRequest && dataRequest) fetchData();
        }
    }, [dataID, refresh]);

    return { status, result };
};

export {useGoogleSheets, getID, FETCH_STATE};