import { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const MODAL_SIZE = {
    small: 'small',
    normal: 'normal',
    large: 'large'
}

const MODAL_POSITION = {
    center: 'center',
    
    right: 'right'
}

const shadow = `
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.008),
        0 6.7px 5.3px rgba(0, 0, 0, 0.012),
        0 12.5px 10px rgba(0, 0, 0, 0.015),
        0 22.3px 17.9px rgba(0, 0, 0, 0.018),
        0 41.8px 33.4px rgba(0, 0, 0, 0.022),
        0 100px 80px rgba(0, 0, 0, 0.03)
    ;
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    will-change: backdrop-filter;

    // transition: backdrop-filter 0.3s ease-in-out;

    &.blur
    {
        // transition: backdrop-filter 0.6s ease-in-out;
        backdrop-filter: blur(10px);
    }

    &.clickThrough
    {
        pointer-events: none;
    }
`

const ModalPositioner = styled.div`
    position: absolute;
    top: 0;
    // width: 0;
    height: 0;
    left: 0;
    transition: transform 0.25s ease-in-out;
    
`

const ModalContent = styled.div`

    

    padding: ${
        props => {
            switch(props.size)
            {
                case MODAL_SIZE.small: 
                    return props.theme.layout.paddingSmall;
                case MODAL_SIZE.large: 
                default:
                    return props.theme.layout.paddingLarge;
                
            }
        }
    };
   
    text-align: ${
        props => {
            switch(props.size)
            {
                case MODAL_SIZE.small: 
                case MODAL_SIZE.large: 
                    return 'left';
                default:
                    return 'center';
                
            }
        }
    };
    font-size: .8rem;
    
    max-width: ${
        props => {
            switch(props.size)
            {
                case MODAL_SIZE.small: 
                    return `calc(300px - ${props.theme.layout.paddingSmall} * 2);`;
                case MODAL_SIZE.large: 
                    return `calc(600px - ${props.theme.layout.paddingLarge} * 2);`;
                default:
                    return `calc(400px - ${props.theme.layout.paddingLarge} * 2);`
            }
        }
    };
    
    width: ${
        props => {
            switch(props.size)
            {
                case MODAL_SIZE.small: 
                    return `calc(100vw - ${props.theme.layout.paddingSmall} * 2);`;
                case MODAL_SIZE.large: 
                default:
                    return `calc(100vw - ${props.theme.layout.paddingLarge} * 2);`
            }
        }
    };

    p
    {
        margin-top: ${props => props.size === MODAL_SIZE.small ? props.theme.layout.paddingSmall : '3px'};
        ${props => props.size === MODAL_SIZE.small ? 'margin: 0;' : ''}
    }

    h1
    {
        margin-top:0;
        margin-bottom: ${props => props.theme.layout.padding};
    }
    
    h2
    {
        margin-top: ${props => props.size === MODAL_SIZE.small ? '0' : props.theme.layout.padding};
        font-size: .8rem;
        font-weight: ${props => props.theme.typography.weightBold};
        margin-bottom: ${props => props.size === MODAL_SIZE.small ? props.theme.layout.paddingSmall : '0'};
        text-transform: uppercase;
        color: ${props => props.theme.colors.primary};
    }

    .category
    {
        padding: 5px 8px;
        display: inline-block;
        border: 1px solid ${props => props.theme.colors.keyline};
        border-radius: 5px;
        margin-top: ${props => props.theme.layout.paddingSmall};
    }
`

const ModalContentContainer = styled.div`
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;

    max-height: 70vh;
    overflow-y: auto;
`

const ModalStatusText = styled.div`
    font-size: 1.6rem;
`

const ModalBox = styled.div`


    background-color: var(--color-light);
    border-radius: var(--size-radius);
    text-align: center;
    
    transform: ${
        props => {
            switch(props.position)
            {
                case MODAL_POSITION.right: 
                    return `translateX(10%) translateY(-50%);`;
                default:
                    return `translateX(-50%) translateY(-50%);`
            }
        }
    }; 
    
    transition-property: transform, width, height;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    overflow: hidden;

    ${shadow}
`;

const ModalNextButton = styled.button`
    position: absolute;
    left: 50%;
    top: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translateX(${props => props.theme.layout.padding}) translateY(-50%);
    ${shadow}

    &:focus
    {
        outline: none !important;
    }
`

function Modal({children, clickThrough, blur, onClose, position, offset, onNext})
{
    const modalContent = useRef();
    const [size, setSize] = useState({width: 0, height: 0});
    const [pos, setPos] = useState({x: 0, y: 0});

    const onNewContent = () => {
        const newSize = {
            width: modalContent.current.offsetWidth + 'px',
            height: modalContent.current.offsetHeight + 'px'
        }

        setSize(newSize);
        
    }

    useEffect(onNewContent, [children])
    useEffect(() => {
        if(position) setPos(position);
    }, [position])

    return <Overlay className={`${clickThrough ? 'clickThrough' : ''} ${blur ? 'blur': ''}`}>
        {!clickThrough && <div className="click-off" onClick={() => {if(onClose) onClose()}}></div>}
        <ModalPositioner style={{transform: `translateX(${pos ? pos.x + (typeof pos.x === 'string' ? '' : 'px') : '50vw'}) translateY(${pos ? pos.y + (typeof pos.y === 'string' ? '' : 'px') : '50vh'})`}}>
            <ModalBox style={size} position={offset}>
                <ModalContentContainer ref={modalContent}>
                    { children }
                </ModalContentContainer>
            </ModalBox>

            { onNext && (<ModalNextButton onClick={() => onNext()}>Next</ModalNextButton>)}
        </ModalPositioner>
    </Overlay>
}

export { Modal, ModalContent, MODAL_SIZE, ModalStatusText, MODAL_POSITION }