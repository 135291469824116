const LightTheme = {
    colors: {
        keyline: `#E4E8F1`,
        background: `#F5F9FA`,
        primary: `#32B6BC`
    },
    typography: {
        font: `'Poppins', sans-serif`,
        weightBold: 400,
        weightNormal: 300
    },
    layout: {
        padding: `2rem`,
        paddingSmall: `1rem`,
        paddingLarge: `3rem`,
        radius: `1rem`
    }
};

export { LightTheme };