import { FETCH_STATE } from "../hooks/useGoogleSheet";
import { useForm } from "react-hook-form";

import { Form, InputError, InputGroup, FormButton } from "./form";

const GoogleSheetsInput = ({status, onURL}) => {

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = data => {

        const enteredURL = data.urlInput.split('?')[0] + '?r=' + Math.random();
        onURL(enteredURL);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
                <input disabled={status === FETCH_STATE.fetching} name="urlInput" placeholder="Spreadsheet URL" ref={register({ required: true })} />
                {errors.url && <InputError>{errors.url}</InputError>}
                {status === FETCH_STATE.error && <InputError>ERROR</InputError>}
            </InputGroup>
            
            <FormButton type="submit" value="Get Started"/>

            {status === FETCH_STATE.fetching && <span>Loading...</span>}
        </Form>
    );
}

export { GoogleSheetsInput }