import { useEffect, useState, useRef } from "react";
import { select, forceSimulation, interpolate, scaleLinear, forceX, forceY, forceCenter, forceManyBody, forceCollide } from "d3";
import styled from "styled-components";

const ChartType = {
    bubble: 'bubble',
    bar: 'bar',
    list: 'list'
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  font-family: ${props => props.theme.typography.font};
`;

const BubbleChart = ({data, onOver, onSelect, selected = null, chartType = ChartType.bubble}) => {

    const [size, setSize] = useState({width: 0, height: 0});
    const [svg, setSVG] = useState(null);
    const [nodes, setNodes] = useState(null);
    const [labels, setLabels] = useState(null);
    const [positions, setPositions] = useState({})
    const [simulation, updateSim] = useState(forceSimulation().force("charge", forceManyBody().strength(-15)));

    const container = useRef(null);

    const onResize = () => 
    {
        setSize({
            width: container.current.clientWidth,
            height: container.current.clientHeight
        });

        if(svg)
        {
            svg
            .attr("width", size.width)
            .attr("height", size.height);
        }
    }

    const init = () => {

        onResize();

        const _svg = select(container.current)
            .append("svg")
            .attr("width", size.width)
            .attr("height", size.height)
            .style("overflow", "visible")
        
        var _nodes = _svg.append("g")
            .attr("class", "node")
                
        var _labels = _svg.append("g")
            .attr("class", "labels")
            .style("pointer-events", 'none')
                
        setSVG(_svg);
        setNodes(_nodes);
        setLabels(_labels);

        window.addEventListener("resize", onResize);

        let _container = container.current;
        return () => {
            select(_container).selectAll("*").remove();
            window.removeEventListener("resize", onResize);
        }
    }

    const onData = () =>
    {
        simulation.alpha(0).stop();

        // console.log('selected', selected)

        let totalAreaNeeded = data.length ? data.map(d => d.size).reduce((t, d) => t + d) / data.length : 0;
        let maxAreaAvailable = size.width * size.height;
        let area = (maxAreaAvailable * (1 - totalAreaNeeded)) * 0.2;

        let minSize = 5;
        let maxSize = Math.max(20, Math.sqrt(area) / Math.PI);

        if(data && svg)
        {
            let _data = data.map(d => {
                return {
                    ...d, 
                    x: positions[d.id] ? positions[d.id].x : 0,
                    y: positions[d.id] ? positions[d.id].y : 0,
                    radius: positions[d.id] ? positions[d.id].r : 0,
                }
            })

            svg
                .attr("width", size.width)
                .attr("height", size.height);

            let absValueDomain = [0,1]; //extent(data.map(bubble => bubble.size ? Math.abs(bubble.size) : 0));

            let radius = scaleLinear().domain(absValueDomain).range([minSize, Math.max(40,maxSize)]);
            let height = scaleLinear().domain(absValueDomain).range([5, size.height * 0.45]);
            let labelScale = scaleLinear().domain([minSize, 400]).range([0, 1]);
            let gap = 5;
            let pad = 100;
            let width = ((size.width - (pad * 2)) / _data.length) - gap;

            let startX = (size.width * 0.5) - (((width + gap) * (_data.length)) / 2);
            let xPos = scaleLinear().domain([0, _data.length - 1]).range([startX, size.width - (startX * 2)]);

            const getBubble = {
                x: d => {
                    let r = -radius(Math.abs(d.size) || 0);
                    return Math.max(r, Math.min(size.width - r, d.x))
                },
                y: d => {
                    let r = -radius(Math.abs(d.size) || 0);
                    return Math.max(r, Math.min(size.height - r, d.y));
                },

        //         .attr("cx", function(d) { return d.x = Math.max(radius, Math.min(width - radius, d.x)); })
        // .attr("cy", function(d) { return d.y = Math.max(radius, Math.min(height - radius, d.y)); });

                opacity: d => 1 ,
                radius: d => radius(Math.abs(d.size) || 0),
                width: d => radius(Math.abs(d.size) || 0) * 2,
                height: d => radius(Math.abs(d.size) || 0) * 2,
                transform: d =>  `translate(${-radius(Math.abs(d.size) || 0)} ${-radius(Math.abs(d.size) || 0)})`,
                borderRadius: d => maxSize * 100,
                labelOpacity: d => radius(Math.abs(d.size) || 0) > 30 ? 1 : 0,
                labelAnchor: d => 'middle',
                labelRotate: d => 0,
                labelX: d => 0,
                labelY: d => 0,
                labelScale: d => labelScale(d.radius)
            }
            
            const getBar = {
                x: (d, i) => xPos(d.index || i),
                y: d => (size.height * 0.75) - (d.size >= 0 ? height(Math.abs(d.size) || 0) : 0) ,
                opacity: d => 1,
                radius: d => 0,
                width: d => width,
                height: d => height(Math.abs(d.size) || 0),
                transform: d =>  `translate(0 0)`,
                borderRadius: d => 0,
                labelOpacity: d => 1,
                labelAnchor: d => d.size >= 0 ? 'end' : 'start',
                labelRotate: d => 270,
                labelX: (d, i) => {
                    console.log(i, d.index);
                    return xPos(d.index || i) + (width * 0.5)
                },
                labelY: d => (size.height * 0.5) + (d.size >= 0 ? 10 : -10),
                labelScale: d => 0.3
            }

            const getValue = (type, d, i = 0) => {
                switch (chartType)
                {
                    case ChartType.bar:
                        return getBar[type](d, i);
                    case ChartType.bubble:
                    default:
                        return getBubble[type](d, i);
                }
            }

            // create and remove items

            let node = nodes.selectAll("rect").data(_data, d => d.id);

                node.exit()
                    .transition()
                    .duration(1000)
                    .attr("width", 0)
                    .attr("height", 0)
                    .attr('x', d => (d.x + (Math.random() * 300) - 150))
                    .attr('y', d => (d.y + (Math.random() * 300) - 150))
                    .remove()

            let allNodes = node.enter().append("rect")
                    .attr('data-id', d => d.id)
                    // .on('click', (d, i) => onClick(i))
                    .on('mouseover', (e, d, i) => {
                        
                        if(onOver && d.interactive) onOver(d);
                    })
                    .on('mouseout', (e, d, i) => {
                        
                        if(onOver && d.interactive) onOver();
                    })
                    .on('click', (e, d, i) => {
                        // console.log('click');
                        if(onSelect && d.interactive) onSelect(d);
                    })
                    .attr("x", d => {
                        
                        return d.x = positions[d.id] ? positions[d.id].x : Math.random() * size.width;
                    } )
                    .attr("y", d => {
                        return d.y = positions[d.id] ? positions[d.id].y : Math.random() * size.height;
                    })
                    
                    .merge(node);
            
            let label = labels.selectAll("text").data(_data, d => d.id)

                label.exit().remove()
    
            let allLabels = label.enter().append('text')
                        .style('opacity', 0)
                        .style('font-size', '50px')
                        .style('fill', 'white')
                        .attr('transform', d => `translate(${getValue('labelX', d)} ${getValue('labelY', d)}) rotate(${getValue('labelRotate', d)})`)
                        .attr('alignment-baseline', 'middle')
                        .text(d => d.label)
                        .each(function(){
                            var self = select(this);
                            var width = 350;
                            var padding = 0;
                            var textLength = self.node().getComputedTextLength();
                            var text = self.text();
                            while (textLength > (width - 2 * padding) && text.length > 0) {
                                text = text.slice(0, -1);
                                self.text(text + '...');
                                textLength = self.node().getComputedTextLength();
                            }
                        })
                        .merge(label)

                allLabels
                    
                    .style('text-anchor', d => getValue('labelAnchor', d))
                    
                    
            
        
            if(chartType === ChartType.bubble)
            {   
                const tweenSize = function(d){
                    var that = select(this);
                    var prev = that.attr('previous-radius');
                    var i = interpolate(prev, getValue('width', d));
                    return t => {
                        // console.log(i(t))
                        d.radius = i(t);
                        that.attr('width', d => d.radius);
                        that.attr('height', d => d.radius);
                        simulation.nodes(_data)
                    }
                }

                allNodes
                    .attr('previous-radius', function(d){
                        let self = select(this);
                        return self.attr('radius') || 0;
                    })
                    .style("cursor", d => onSelect && d.interactive ? "pointer" : "normal")
                    // .attr('x', function(d) {
                    //     let self = select(this);
                        
                    //     return d.x = self.attr('x') || 0;
                    // })
                    // .attr('y', function(d){
                    //     let self = select(this);
                        
                    //     return d.y =  self.attr('y') || 0;
                    // })
                    .attr('radius', d => getValue('width', d))
                    .transition()
                    .delay((d, i) => {
                        if(selected) return 0;
                        return (d.size < .5 ? 10 : 30) * (i/ 2)
                    })
                    .duration(d => {
                        if(selected) return 500;
                        return 1000;
                    })
                    // .style("opacity", d => getValue('opacity', d))
                    .attr("rx", d => getValue('borderRadius', d))
                    .attr("ry", d => getValue('borderRadius', d))
                    // .attr("width", d => getValue('width', d))
                    // .attr("height", d => getValue('height', d))
                    .attr("fill", d => d.color)
                    .attr("transform", d => getValue('transform', d))
                    .tween('radius', tweenSize)
                    // .tween('height', tweenSize);

                allLabels
                    .transition()
                    .delay((d, i) => 5 * i)
                    .duration(300)
                    // .style('font-size', d => maxSize / 4 + 'px')
                    .style('opacity', d => getValue('labelOpacity', d))

                const _strength = d => {
                    // return .1;
                    // if(!selected) return .1;
                    if(d.id === selected || d.position) return .2;
                    return .1;
                }  

                const _xCenter = d => {
                    // return size.width * .5;
                    // console.log(d, selected, size)
                    if(d.position && d.position.x) return size.width * d.position.x;
                    // if(!selected || !d) return size.width * .5;
                    // if(d.id === selected) return size.width * 0.25;
                    return size.width * 0.66;
                }
                
                const _yCenter = d => {
                    // return size.width * .5;
                    // console.log(d, selected, size)
                    if(d.position && d.position.y) return size.height * d.position.y;
                    return size.height * 0.5;
                }

                simulation
                    .nodes(_data)
                    // .force("forceX", forceX().strength(d => _strength(d)).x(d => _xCenter(d)))
                    // .force("forceY", forceY().strength(d => _strength(d)).y(size.height * .5))
                    // .force("center", forceCenter().x(d => _xCenter(d)).y(size.height * .5))
                    .force("forceX", forceX().strength(d => _strength(d)).x(d => _xCenter(d)))
                    .force("forceY", forceY().strength(d => _strength(d)).y(d => _yCenter(d)))
                    // .force("center", forceCenter().x(size.width * .5).y(size.height * .5))
                    .force("collide", forceCollide().strength(1).radius(d => d.radius / 2 + 3 ).iterations(5))
                    .on("tick", d => {
                        
                        if(chartType === ChartType.bubble)
                        {
                            allNodes.each(d => {
                                positions[d.id] = {};
                                let r = radius(d.size);
                                let y = Math.max(r, Math.min(size.height - r, d.y));
                                let x = Math.max(r, Math.min(size.width - r, d.x));

                                positions[d.id].x = x;
                                positions[d.id].y = y;
                                positions[d.id].r = d.radius;

                                d.x = x;
                                d.y = y;

                                setPositions(positions);
                            })
                            allNodes.attr("x", d => d.x);
                            allNodes.attr("y", d => d.y);
                            // allNodes.attr("width", d => d.radius || 0);
                            // allNodes.attr("height", d => d.radius || 0);
                            
                            allLabels.attr("transform", d =>  `translate(${d.x} ${d.y}) rotate(0) scale(${getValue('labelScale', d)})`);

                            
                        }
                    })
                    .alpha(0.5)
                    .restart();  
            
                updateSim(simulation);
            }
            else
            {
                

                allNodes
                    .each(function (d){ d.x = getValue('x', d); d.y = getValue('y', d)})
                    .transition()
                    .delay((d, i) => 5 * i)
                    .duration(700)
                    .style("opacity", d => getValue('opacity', d))
                    .attr("rx", d => getValue('borderRadius', d))
                    .attr("ry", d => getValue('borderRadius', d))
                    .attr("x", d => getValue('x', d) )
                    .attr("y", d => getValue('y', d) )
                    .attr("width", d => getValue('width', d))
                    .attr("height", d => getValue('height', d))
                    .attr("fill", d => d.color)
                    .attr("transform", d => getValue('transform', d));
                
                allLabels
                    .transition()
                    .delay((d, i) => 5 * i)
                    .duration(700)
                    .attr('transform', (d, i )=> `translate(${getValue('labelX', d, i)} ${getValue('labelY', d, i)}) rotate(${getValue('labelRotate', d, i)})`)
                    .style('opacity', d => getValue('labelOpacity', d))

            }

            //let group = svg.selectAll('g').transition().duration(750)
            // if(selected === null)
            // {
            //     group.attr('transform', `translate(0 0)`)
            // }
            // else
            // {
            //     if(chartType === ChartType.bubble)
            //     {
            //         group.attr('transform', `translate(${0 - (data[selected].x - (size.width * .2)) || 0} ${0 - (data[selected].y - (size.height * .5)) || 0})`)
            //     }
            //     else
            //     {
            //         group.attr('transform', `translate(${0 - (data[selected].x - (size.width * .25)) || 0} 0`)
            //     }
            // }
                // .alpha(0.5).restart();   
        }

        
    }

    useEffect(onData, [data, svg, size, nodes, labels, chartType, selected]);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(init, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Wrapper ref={container}></Wrapper>
    )
}

export { BubbleChart }