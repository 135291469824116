import styled from "styled-components";

const Form = styled.form`
    input {
        padding: .7rem .8rem;
        border-radius: 6px;
        border: solid 1px #E4E8F1;
    }
`;

const InputGroup = styled.div`
    width: 100%;
    margin-bottom: 1.4rem;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  
`;

const InputError = styled.span`
    color: red;
    font-size: 80%;
    margin-top: .5rem;
`

const FormButton = styled.input`
    background-color: #259DA3;
    color: white;
    border: none !important;
    cursor: pointer;

    &:hover
    {
        background: #2DC0C8;
    }
`

export { Form, InputGroup, InputError, FormButton }